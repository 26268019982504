// www.the-guild.eu x1 local.less

// X-styles
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 200 1000;
  src: url(../fonts/mulish-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200 1000;
  src: url(../fonts/mulish-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200 1000;
  src: url(../fonts/nunito-italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200 1000;
  src: url(../fonts/nunito-normal.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "PT serif";
    font-style: normal;
    font-display: swap;
    src: url("../fonts/ptserif-normal.woff2");
  }
  // @font-face {
  //   font-family: "Ubuntu";
  //   font-style: normal;
  //   font-display: swap;
  //   src: url("../fonts/ubuntu-normal.woff2");
  // }
  // @font-face {
  //   font-family: "Ubuntu";
  //   font-display: swap;
  //   src: url("../fonts/ubuntu-thin.woff2");
  //   font-weight: 300;
  // }
  // @font-face { //regular
  //   font-family: "Avenir Medium";
  //   font-display: swap;
  //   font-style: normal;
  //   src: url("../fonts/avenir/Avenir-Medium.woff2");
  // }
  // @font-face { //regular italic
  //   font-family: "Avenir Medium";
  //   font-display: swap;
  //   font-style: italic;
  //   src: url("../fonts/avenir/Avenir-MediumOblique.woff2");
  // }
  // @font-face { //regular bold
  //   font-family: "Avenir Medium";
  //   font-display: swap;
  //   font-style: normal;
  //   font-weight: bold;
  //   src: url("../fonts/avenir/Avenir-Black.woff2");
  // }
  // @font-face { //regular italic bold
  //   font-family: "Avenir Medium";
  //   font-display: swap;
  //   font-style: italic;
  //   font-weight: bold;
  //   src: url("../fonts/avenir/Avenir-BlackOblique.woff2");
  // }
  // @font-face { //book
  //   font-family: "Avenir Book";
  //   font-display: swap;
  //   font-style: normal;
  //   src: url("../fonts/avenir/Avenir-Book.woff2");
  // }
  // @font-face { //book italic
  //   font-family: "Avenir Book";
  //   font-display: swap;
  //   font-style: italic;
  //   src: url("../fonts/avenir/Avenir-BookOblique.woff2");
  // }
  // @font-face { //book bold
  //   font-family: "Avenir Book";
  //   font-display: swap;
  //   font-style: normal;
  //   font-weight: bold;
  //   src: url("../fonts/avenir/Avenir-Medium.woff2");
  // }
  // @font-face { //book italic bold
  //   font-family: "Avenir Book";
  //   font-display: swap;
  //   font-style: italic;
  //   font-weight: bold;
  //   src: url("../fonts/avenir/Avenir-MediumOblique.woff2");
  // }
  // @font-face { // black
  //   font-family: "Avenir Black";
  //   font-display: swap;
  //   font-style: normal;
  //   font-weight: normal;
  //   src: url("../fonts/avenir/Avenir-Black.woff2");
  // }
  // @font-face { //black italic
  //   font-family: "Avenir Black";
  //   font-display: swap;
  //   font-style: italic;
  //   font-weight: normal;
  //   src: url("../fonts/avenir/Avenir-BlackOblique.woff2");
  // }
//   @font-face { //black bold
//     font-family: "Avenir Black";
//     font-display: swap;
//     font-style: normal;
//     font-weight: bold;
//     src: url("../fonts/avenir/Avenir-Heavy.woff2");
//   }
//   @font-face { //black bold italic
//     font-family: "Avenir Black";
//     font-display: swap;
//     font-style: italic;
//     font-weight: bold;
//     src: url("../fonts/avenir/Avenir-HeavyOblique.woff2");
//   }

@screen-small: ~"min-width: 401px";
@screen-below-small: ~"max-width: 400px";

/*------------------------------------*\
# colour, fonts
\*------------------------------------*/
:root {
    --width-soft-max: 750px;
    --width-text-max: 750px;
    --space-xs: 10px;
    --space-s: 20px;
    --space-m: 40px;
    --space-l: 80px;
    
    --textColor: #262626;
    --color-primary: #4a2359;
    --color-primary--darker: #290138; //#280136;
    --color-primary--lighter: #A381B0;//#9E85B1; //#c094c2;
    --color-primary--air: #D4C6D9;//#E7E0EB;
    --color-secondary: #ECEEF6; //#e4f0f6; 
    --color-secondary--darker: #b5ceeb;
   // supplementary colors
    --color-light: #ffffff;
    --color-dark: #010101;
    --color-link: var(--color-primary--darker);//#126c93;
    --color-neutral: #949494;
    --color-neutral--dark: #666;
    --color-neutral--light: #ECEEF6;
    --color-neutral--hover: #E7E0EB;
    // fonts
    // --font-body-small: "Avenir Book";
    // --font-body: "Avenir Medium";
    // --font-heading:"Avenir Book";
    // --font-heading: "PT Serif", serif;
    // --font-body: "Ubuntu", sans-serif;
    --font-heading: "Mulish", sans-serif;
    --font-body: "Nunito", sans-serif;
    --font-body-small: "Nunito", sans-serif;
    --font-size-large-20: 2.6rem;
    --font-size-base: 1.8rem;
    --font-size-tiny: 1.6rem;
    // assigned colors
    --color-lines: #f0f0f0;
    --color-featured-bg: var(--color-neutral--light);
    --color-navigation-links: var(--color-primary--darker);
    --color-tags: var(--color-neutral--light);//#f5f7f8;
    --color-tag-text: var(--color-neutral--dark);
    --color-breadcrumbs: var(--color-neutral--dark);
    --color-read-more-marker: var(--color-primary--lighter);
    --color-banner: var(--color-primary--darker);
    --color-special--dark: var(--color-primary--darker);
    --color-alternate-row: var(--color-neutral--light);
    --color-menu: linear-gradient(180deg, var(--color-primary--darker) 0%, var(--color-primary) 100%);
    --color-footer: var(--color-primary);
    --color-banner-text: white;
    --color-menu-text: white;
    --color-footer-text: white;
    --color-bullet: var(--color-primary);
    --color-neutral-tint-opaque-95: var(--color-neutral--light);

    --color-button: var(--color-neutral--light);
    --color-button-text: var(--color-primary--darker);
    --color-button-hover: white;
    --color-button-link: white;
    --color-button-link-text: var(--color-primary--darker);
    --color-button-loud-link: var(--color-primary);
    --color-button-loud-link-hover: var(--color-primary--darker);
    --color-alternate-row-button: white;

    // logo
    --logo: url(../images/logo-inv.svg);
    // icons
    --icon-search-inv: url(../images/search.svg);
    --icon-search: url(../images/search-white.svg);
    --icon-menu: url(../images/menu-white.svg);
    --arrow-white: url(../images/arrow-white.svg);
    --arrow: url(../images/arrow.svg);
}

h1, h2, h2 a, h3, h4 {
  font-family: var(--font-heading);
}

#vrtx-structured-article,
#vrtx-structured-event {
  h1, .vrtx-introduction {
    color: var(--color-primary--darker);
  }
}

h3 {
  a {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

body {
    font-family: var(--font-body);
}
.main a {
    color: var(--color-link);
}

p, ul, ol, dl, li {
  text-align: left !important;
}
center {
  margin-bottom: var(--space-s);
}

ul ::marker {
  color: var(--color-bullet);
}

#head-wrapper {
    background-color: var(--color-banner);
    padding: 10px 0;
    #head {
        #header {
            .logo {
                height: 100px;
                background: none;
                .title {
                    background: var(--logo) no-repeat;
                    height: 100%;
                    width: 310px;
                    display: block;
                    text-indent: -100000px;
                    background-size: contain;
                }
            }
            #intranet {
                display: none;
            }
            #header-tools {
                color: var(--color-banner-text);
                .toggle-search::before {
                    background-image: var(--icon-search);
                }
                .sidebar-menu-toggle::before {
                    background-image: var(--icon-menu);
                    width: 36px;
                }
            }
        }
    }
}
li.root-folder.parent-folder {
  display: none;
}
#main-menu {
    background: var(--color-menu);
    color: var(--color-menu-text);
}
.sidebar-menu-wrapper {
    .sidebar-global-menu {
        border: none;
        max-width: 610px;
    }
    #supplementary-menu {
        display: block;
    }
}

@media (@screen-medium) {
  #vrtx-tags.vrtx-tags-sets-2 {
    column-count: 2;
  }
  #vrtx-tags.vrtx-tags-sets-3 {
    column-count: 3;
  }
  #vrtx-tags.vrtx-tags-sets-4 {
    column-count: 4;
  }
  #vrtx-tags.vrtx-tags-sets-5 {
    column-count: 5;
  }
  #vrtx-tags.vrtx-tags-sets-6 {
    column-count: 6;
  }
}

.grid-container {
  margin-bottom: calc(3* var(--space-s));
}

// Used in news-and-blog 
.grid-container.row-all-colored.no-padding-bottom {
  padding-bottom: 5px;
}
.grid-container.row-all-colored.no-padding-top {
  padding-top: 0;
}

.grid-container {
  &.media-wide, .media-wide, &.background-video, .background-video {
    &.pos-top img {
      object-position: top;
    }
    &.pos-bottom img {
      object-position: bottom;
    }
    &.pos-left img {
      object-position: top;
    }
    &.pos-right img {
      object-position: right;
    }
  }
}

// Search
#vrtx-searchview {
  @media (@screen-large) {
    #vrtx-content {
      margin-left: 200px;
    }
  }
  .vrtx-search-results-container {
    max-width: var(--width-text-max);
  }
  .vrtx-big-search {
    background: none;
    padding: 0;
    margin-bottom: var(--space-s);
  }
}
div#vrtx-search-more-setting {
  margin: var(--space-s) 0;
}
.vrtx-search-main-links-box.vrtx-frontpage-box {
  display: flex;
  align-items: center;
  gap: var(--space-s);
  flex-wrap: wrap;
  @media (@screen-below-small) {
    margin-top: 40px;
  }
  
  #vrtx-search-main-link-altinnhold {
    position: relative;
    a {
      padding: 15px 60px 15px 15px;
      border: 2px solid var(--color-button-loud-link);
      background-color: var(--color-button-link); 
      color: var(--color-button-link-text);
      text-decoration: none;
      &::after {
        content: "";
        background: var(--arrow) no-repeat center;
        background-size: contain;
        width: 24px;
        height: 1.5em;
        position: absolute;
        right: var(--space-s);
        transition: all 0.3s
      }
      &:hover, &:focus {
        background-color: var(--color-button-hover);
      }
      &:hover::after {
        right: 5px;
      }
    }
  }
}

.vrtx-search-facets-box.vrtx-frontpage-box, .vrtx-search-open-facets-box {
  display: flex;
  gap: var(--space-s);
  flex-wrap: wrap;
  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: var(--space-xs);
    li::marker {
      color: transparent;
    }
  }
  .vrtx-search-facets-title {
    font-family: var(--font-heading);
    font-weight: 700;
  }
  .vrtx-search-facets-selected.vrtx-search-facets-default, .vrtx-search-facets-selected {
    background: var(--color-neutral--light);
    padding: 10px;
    font-weight: 700;
    display: inline-block;
  }
}
.vrtx-search-facets-box li:has(.vrtx-search-facets-selected) {
  order: -1;
}
.vrtx-search-open-facets-box {
  ul li {
    background-color: var(--color-primary--air);
    border: 2px solid transparent;
    padding: var(--space-xs);
    width: fit-content;
    a{
      text-decoration: none;
      color: black;
      &::before {
        content: "X";
        padding-right: 5px;
        color: black;
      }
    }
    &:hover, &:focus {
      background-color: var(--color-button-hover);
    }
  }
}
.vrtx-search-facets-box.vrtx-frontpage-box {
  gap: var(--space-s) var(--space-l);
  &.hidden {
    display: none;
  }
}

#vrtx-searchview .hits {
  background-color: transparent;
  padding: 0;
  margin-top: var(--space-m);
  margin-bottom: var(--space-s);
  font-family: var(--font-heading);
  font-weight: 600;
}

#vrtx-event-listing .main .vrtx-subfolder-menu {
  display: none;
}

.vrtx-feed .item-title, .vrtx-event-component-title.summary {
  font-family: var(--font-heading);
}
#vrtx-blog-listing, #vrtx-article-listing, #vrtx-tagview, #vrtx-searchview, body.subsection  {
    h1 {
      margin-top: var(--space-m);
    }
    #breadcrumbs:has(.breadcrumb) + h1 {
      margin-top: 0;
    }
  }  
  #breadcrumbs {
    font-size: 1.5rem;
    font-family: var(--font-body-small);
    #vrtx-breadcrumb-wrapper {
     // margin-bottom: 0;
      &:has(.vrtx-breadcrumb-before-active) {
        padding-top: var(--space-s);
        margin-bottom: var(--space-s);
      }
    }
    a {
      color: var(---color-breadcrumbs);
      text-decoration: none;
    }
    span::after {
      opacity: 1;
    }
  }
  body.the-frontpage {
    #breadcrumbs .vrtx-breadcrumb-active {
      display: none;
    }
  }
  #total-main:has(.media-wide) #breadcrumbs #vrtx-breadcrumb-wrapper {
    margin-bottom: 0;
  }


input.vrtx-search-field {
    background: white;
    border: 2px solid var(--color-dark);
}

// Real button
button {
    border-radius: 0;
    border: 2px solid transparent;
    padding: 14px 12px;
    background-color: var(--color-button); 
    &:hover, &:focus {
      background-color: var(--color-button-hover);
    }
    &[aria-expanded="true"]{
      border: 2px solid var(--color-primary--darker);
    }
}

#header-tools .search-form,  
#main-menu .search-form, 
.vrtx-search-container, 
body#error .error-404, 
#search-container, .main, 
#vrtx-searchview {
    form, form.search-form, .vrtx-big-search > div, fieldset {
      column-gap: 0;
      button {
        background-image: var(--icon-search);
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--color-button-loud-link);
        padding: 17px 30px;
        border-width: 2px 2px 2px 1px;
        border-radius: 0;
        text-indent: -100000px;
        &:hover, &:focus {
        background-color: var(--color-button-loud-link-hover);
        }
      }
    }
  }
  
  #header-tools .search-form, #main-menu .search-form {
    bottom: 0;
    form {
      input, button, select {
        border-color: white;
      }
    }
  }

// fake button
.main .button, .button-loud {
    border-radius: 0;
    color: white;
    @media (@screen-below-medium) {
        font-size: 1.8rem;
    }
}
.main .button {
  border: 2px solid var(--color-button-loud-link);
  background-color: var(--color-button-link); 
  color: var(--color-button-link-text);
  &:hover, &:focus {
    background-color: var(--color-button-hover);
  }
}

.main .button-loud{
  background-color: var(--color-button-loud-link); 
  color: white;
  &:hover, &:focus {
    background-color: var(--color-button-loud-link-hover);
  }
}

.grid-container.row-all-colored {
  background-color: var(--color-alternate-row);
}

.grid-container.row-all-no-margin-bottom {
  margin-bottom: 0;
}

.navigation-links li a {
    border-radius: 0;
    background-color: var(--color-alternate-row);
    color: var(--color-navigation-links);
}

.vrtx-frontpage-box.link-special {
  padding: 4% 15% 4% 10%;
  a {
    color: var(--color-dark);
    font-family: var(--font-heading);
    &::after {
      display: none;
    }
  }
}
.vrtx-frontpage-box.box-special .vrtx-box-content {
  padding: 7% 15% 7% 10%;
}

.vrtx-frontpage-box {
    &.box-special,
    &.img-special-left,
    &.img-special-right {
        background: var(--color-alternate-row);
    }
}

.main {
    .read-more, .vrtx-more a, .vrtx-frontpage-box.link-special a {
        display: inline;
        color: inherit;
        text-decoration: none;
        background-image: linear-gradient(var(--color-read-more-marker), var(--color-read-more-marker));
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 1.5em 0.23em;
        transition: background-size .4s;
        padding-bottom: 3px;
        &:hover, &:focus {
            text-decoration: none;
            background-size: 100% 0.23em;;
        }
        &::before {
            display: none;
        }
    }
  
    .vrtx-frontpage-box.link-special {
      .vrtx-box-content {
        @media (@screen-large) {
          font-size: 5rem;
          line-height: 1.4;
        }
      }
      a {
        background-size: 0.9em 0.13em;
        padding-bottom: 0;
        transition: background-size .6s;
        &:hover,
        &:focus {
          background-size: 100% 0.13em;
        }
      }
    }
}

.grid-container, .grid-container.row-all-colored {
  &:has(.dark), &:has(.africa), &:has(.europe) {
  padding: 0;
  background-color: transparent;
      .vrtx-frontpage-box {
            background-color: var(--color-special--dark);
            *,
            a {
                color: white;
            }
            .button-loud {
                color: var(--color-black);
                margin-right: var(--space-s);
            }
            .button {
                //border-color: white;
                color: var(--color-dark);
                &::after {
                    background-image: var(--arrow);
                }
            }
            .read-more, .vrtx-more a, .vrtx-read-more, .all-messages, &.link-special a {
                &::after {
                    background-image: var(--arrow-white);
                }
            }
            ul ::marker {
              color: var(--color-read-more-marker);
          }
        }
    }
}

// Old featured-item
section.vrtx-featured-items {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: var(--space-s);
    .vrtx-featured-item-1 {
        grid-column: 1 / 4;
    }
}

// Featured item
.grid-container .featured-feed-item {
    padding-top: 20px;
    .item-title {
      color: var(--color-primary--darker);
    }
    .vrtx-feed li {
      background-color: var(--color-featured-bg);
      .vrtx-list-item-content {
        padding: 0 var(--space-s);
      }
    }
    
}

blockquote {
  border-bottom: 1px solid var(--color-primary);
  border-top: 1px solid var(--color-primary);
  margin: var(--space-m) 0;
  &::before {
    color: var(--color-primary--lighter);
    font-size: 13rem;
    font-family: "PT sans";
    margin-top: var(--space-s);
  }
  p {
     margin: 0px 0 var(--space-s) 0;
  }
  p:first-child {
    font-style: italic;
    font-size: var(--font-size-large-10);
    color: var(--color-primary--darker);
  }
}

.vrtx-article-body hr {
  border-bottom: 1px solid var(--color-primary);
}

.three-column-list,
.two-column-list {
  .vrtx-box-content {
    ul, ol {
      column-gap: var(--space-m);
      column-count: 1;
      li {
        break-inside: avoid;
      }
    }
  }
}
.three-column-list .vrtx-box-content {
  ul, ol {
    @media (@screen-medium) {
      column-count: 3;
    }
  }
}
.two-column-list .vrtx-box-content {
  ul, ol {
    @media (@screen-medium) {
      column-count: 2;
    }
  }
}

.vrtx-article-body .vrtx-facts-container {
  background: var(--color-alternate-row);
  h2, h3 , h4 {
    color: var(--color-primary--darker);
  }
}

h2.accordion {
  font-size: 3rem;
}
h3.accordion, h4.accordion {
  font-weight: 400;
}
h2, h3, h4 {
    &.accordion {
        font-weight: 400;
        padding: var(--space-s) var(--space-m) var(--space-s) 0;
        margin:0; 
        width: auto;
        display:flex;
        align-items: center;
        &::before{
            display: none;
        }
        &::after{
            content: "";
            background-image: var(--arrow);
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 25px;
            height: 25px;
            right: 0;
            display: inline-block;
            transform: rotate(90deg);
            transition: all .3s ease-in;
        }
    }
}
details {
  margin-bottom: var(--space-s);
  + details {
    margin-top: -20px;
  }
  &.accordion-wrapper {
    align-items: center;
    gap: var(--space-s);
    border-top: 1px solid var(--color-primary--lighter);
    border-bottom: 1px solid var(--color-primary--lighter);
    &:has(+ .accordion-wrapper) {
      border-bottom: none;
    }
    parent:last-child {
      border-bottom: 1px solid var(--color-primary--lighter);
    }
    &[open] {
      .accordion {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
    summary {
      width: 100%;
    }
  }
}

.vrtx-article-body .timeline,
.vrtx-frontpage-box.timeline {
    .prev, .next {
      background-color: var(--color-button);
        &:hover {
          border-color: var(--color-primary);
        }
    }
}
.row-all-colored .vrtx-frontpage-box.timeline {
  .prev, .next {
      background-color: var(--color-alternate-row-button);
  }
}

@media (@screen-large) {
  #vrtx-structured-article #vrtx-content {
    max-width: var(--width-soft-max);
    margin: 0 auto;
    .image-center img {
      margin-left: calc(var(--width-soft-max) / 2 - 50vw);
    }
  }
  #vrtx-stuctured-article #vrtx-introduction-wrapper {
    margin-left: -100px;
  }
}

#vrtx-introduction-wrapper:has(.vrtx-authors) {
  .vrtx-introduction, .vrtx-introduction p {
    margin-bottom: var(--space-s);
  }
}
#vrtx-introduction-wrapper .vrtx-authors {
  margin-bottom: var(--space-s);
}

// Events
.vrtx-date-icon {
    background-color: var(--color-primary);
    color: white;
}

// Publications
.grid-container:has(.vrtx-horizontal-menu) {
    margin-bottom: 60px;
}
ul.vrtx-horizontal-menu {
    display: flex;
    gap: var(--space-s);
    padding-left: 0;
    li {
        background-color: var(--color-secondary);
        padding: 6px 12px;
        &:hover, &:focus {
            background-color: var(--color-neutral--hover);
            text-decoration: none;
        }
        &.vrtx-active-item.vrtx-current-item {
            background-color: var(--color-button-loud-link);
            color: white;
            &:hover, &:focus {
                background-color: var(--color-button-loud-link-hover);
            }
            a {
                color: white;
            }
        }
        &::marker {
            color: transparent;
        }
    }
}
span.filter-label {
    font-family: var(--font-heading);
    font-weight: 700;
}
.grid-container.publications-filter-row {
 padding: var(--space-m) 0;
 margin-bottom: var(--space-m);
}
form.publications-filter {
    column-count: 2;
}
.filter-result {
    margin-bottom: var(--space-s);
    font-size: 2rem;
    font-family: var(--font-heading);
}
.vrtx-feed li.hidden {
    display: none;
}
dl.categories {
    display: none;
}
dl.categories:has(.category--tag) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-xs);
    dt.category--type, dt.category--type + dd, dt.category--tag {
        display: none;
    }
    dt.category--tag + dd {
        border-radius: 50px;
        background: var(--color-tags);
        color: var(--color-tag-text);
        padding: 2px var(--space-xs) 3px var(--space-xs);
        margin-bottom: 0px;
        margin-left: 0;
    }
}

.vrtx-tags-links {
    color: transparent;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    flex-wrap: wrap;
    gap: var(--space-xs);
    column-gap: 3px;
    margin-left: 0;
    margin-top: var(--space-xs);
    margin-bottom: var(--space-m);
    > dd, a {
        border-radius: 50px;
        background: var(--color-tags);
        color: var(--color-dark);
        padding: 2px var(--space-xs) 3px var(--space-xs);
        margin-bottom: 0px;
        margin-left: 0;
        &:before {
        content: "";
        }
    }
}
.blog-author-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-s);
  margin-bottom: var(--space-s);
  transition: all .3s;
  padding: var(--space-s) 0;
  &:has(details.accordion-wrapper[open]) {
    flex-direction: column;
  }
  img {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    max-width: 150px;
    max-height: 150px;
    margin: 0;
  }
  details.accordion-wrapper {
    height: fit-content;
    margin-bottom: 0;
    width: 100%;
  }
  h2 {
    font-size: 2rem;
  }
  p {
    font-size: var(--font-size-tiny);
  }
}
  
  
.vrtx-social-components {
   a {
    text-indent: -100000px;
        &.linkedin {
            background: transparent url("../images/social/linkedin.svg") no-repeat scroll left center;
        }
        &.bluesky {
            background: transparent url("../images/social/bluesky.svg") no-repeat scroll left center;
        }
        &.youtube {
            background: transparent url("../images/social/youtube.svg") no-repeat scroll left center;
        }
        &::before {
            width: 50px;
            height: 50px;
        }
    }
}

li.vrtx-share-at-Twitter {
  display: none;
}

.the-frontpage #global-sub-menu {
  display: block;
}

#footer-wrapper {
  background-color: var(--color-footer);
}